.chatBoxTop {
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;

  }
  
  .chatBoxBottom {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .chatMessageInput {
    width: 80%;
    height: 80px;
    padding: 10px;
  }
  
  .chatSubmitButton {
    width: 70px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: teal;
    color: white;
  }