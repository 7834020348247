.messenger {
    height: calc(100vh - 70px);
    display: flex;
  }
  
  .chatMenu {
    flex: 3.5;
    
  }
  
  .chatMenuInput {
    width: 90%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent;
    font-size: 20px;
  }
  .chatMenuInput::placeholder{
    color: white;
  }
  .chatMenuInput:focus{
    outline: none;
    color: wheat;
  }
  
  .chatBox {
    flex: 5.5;
  }
  
  .chatBoxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .chatOnline {
    flex: 3;
  }
  
  .chatMenuWrapper{
    padding: 10px;
    height: 100%;
    background-color: #7c78aa;
  }
  .chatBoxWrapper,
  .chatOnlineWrapper {
    padding: 10px;
    height: 100%;
    background-color: #ddddf7;
  }
  
  .noConversationText {
    position: absolute;
    top: 10%;
    font-size: 50px;
    color: rgb(224, 220, 220);
    cursor: default;
  }
  
  @media screen and (max-width: 768px) {
    .chatMenu {
      flex: 1;
    }
  
    .chatMenuInput {
      display: none;
    }
  
    .chatBox{
      flex: 10;
    }
  
    .chatOnline{
      flex: 1px;
    }
  }