.sidebar {
    flex: 3;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    position: sticky;
    top: 50px;
    background-color:   #ddddf7;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(179, 179, 179);
  }
  
  .sidebarWrapper {
    padding: 20px;
  }
  
  .sidebarList {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sidebarListItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .sidebarIcon {
    margin-right: 15px;
  }
  
  .sidebarButton {
    width: 150px;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .sidebarHr {
    margin: 20px 0;
  }
  
  .sidebarFriendList {
    padding: 0;
    margin: 0;
    list-style: none;
  }

